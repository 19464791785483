<template>
  <el-dialog :visible.sync="visible" title="提示" width="500px">
    <div v-loading="loading" class="info_box">
      <p class="info_p">
        <span class="title">类型</span><span class="value">{{mainInfo.dataType}}</span>
        <span class="title">版本</span><span class="value">{{mainInfo.version}}</span>
      </p>
      <p>
        <span class="title">操作人</span><span class="value">{{mainInfo.operatorName}}</span>
        <span class="title">时间</span><span class="value">{{mainInfo.time}}</span>
      </p>
    </div>
    <div v-loading="loading" class="error_main">
      <div v-for="error in errorList" :key="error.item" class="error_box">
        <p class="error_title">
          <span>{{errorTypeName[error.item]}}</span>
          <i class="el-icon-success" v-if="!error.msgList.length"></i>
          <i class="el-icon-error" v-else></i>
        </p>
        <div class="error_msg" v-for="(message, index) in error.msgList" :key="index">{{message}}</div>
      </div>
    </div>
    <div slot="footer" class="footer">
      <el-button @click="handlePackAdd" size="mini" :type="submitText==='保存'?'primary':'warning'">{{submitText}}
      </el-button>
      <el-button @click="visible = false" size="mini" type="primary">关闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'CreatePackage',
  props: {
    equipType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      visible: false,
      loading: true,
      submitText: '保存',
      mainInfo: {},
      errorList: [],
      errorTypeName: { page: '页面', menu: '菜单', collect: '购物车', orderQuery: '订单查询页', archive: '档案页' }
    }
  },
  methods: {
    show () {
      this.visible = true
      this.checkPackData()
    },
    async checkPackData () {
      this.errorList = []
      this.loading = true
      const response = await this.$axios.post('athena/data/check', { equipType: this.equipType })
      const { dataType, version, operatorName, time, items } = response.data
      this.mainInfo = { dataType, version, operatorName, time }
      this.errorList = items
      let errorData = this.errorList.find(item => item.success)
      this.submitText = errorData === -1 ? '保存' : '继续创建数据包'
      this.loading = false
    },
    async handlePackAdd () {
      const response = await this.$axios.post('/athena/data/pack', { equipType: this.equipType })
      if (response.code === '0000') {
        this.$message.success('创建成功')
        this.$emit('change')
        this.visible = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.info_box {
  border: 1px solid #ebebeb;
  line-height: 26px;
  font-size: 14px;
  .info_p {
    border-bottom: 1px solid #ebebeb;
  }
  span {
    display: inline-block;
  }
  .title {
    width: 92px;
    padding: 0;
    text-align: center;
    color: #878a92;
    background: #f8f8f8;
    border-right: 1px solid #ebebeb;
  }
  .value {
    min-width: 130px;
    padding-left: 10px;
  }
}
.error_main {
  margin-top: 20px;
  max-height: 360px;
  overflow: auto;
  .error_box {
    border: 1px solid #ebebeb;
    padding: 10px;
    .error_title {
      font-size: 16px;
      i {
        float: right;
        color: #5cbb7a;
        &.el-icon-error {
          color: #F56c6c;
        }
      }
    }
    .error_msg {
      padding-left: 20px;
    }
  }
}
</style>
